import React from "react"
import { graphql } from "gatsby"
import Client from "../components/client-landing/client"
import { Helmet } from "react-helmet"

const ClientLanding = ({ data: { datoCmsClientLanding } }) => {

  return (
    <>
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />
      </Helmet>
      <Client {...datoCmsClientLanding} />
    </>
  )
}

export default ClientLanding

export const query = graphql`
  query ClientLanding($id: String!) {
    datoCmsClientLanding(originalId: { eq: $id }) {
      id: originalId
      title
      desktopVideo
      mobileVideo
    }
  }
`
