import React, { useState, useRef } from "react"
import style from "./client.mod.scss"
import ArrowRightBlack from "../icons/arrow-right-black"
import LogoWhite from "../icons/logo-white"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import thumb from "../../assets/images/thumbnail.jpg"
import thumbMobile from "../../assets/images/thumbnail-square.jpg"

const Client = ({ title, desktopVideo, mobileVideo }) => {
  const variants = {
    inital: {
      y: 20,
      opacity: 0,
    },
    fade: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 3,
        delay: 4,
      },
    },
  }
  const desktopPlayer = useRef(null)
  const mobilePlayer = useRef(null)
  const [play, setPlay] = useState(true)

  return (
    <div className={style.client}>
      {/* <h1>{`Kia Ora ${title}`}</h1> */}
      <div className={style.client__logo}>
        <LogoWhite />
      </div>
      <div className={style.client__video}>
        <video
          ref={desktopPlayer}
          className={style.client__desktop}
          controls
          src={desktopVideo}
          poster={thumb}
        />
        <video
          ref={mobilePlayer}
          className={style.client__mobile}
          controls
          webkit-playsinline
          playsinline
          src={mobileVideo}
          poster={thumbMobile}
        />
        {play && (
          <div
            className={style.client__overlay_desktop}
            onClick={() => {
              setPlay(false)
              desktopPlayer.current.play()
            }}
          >
            <PlayIcon />
          </div>
        )}
        {play && (
          <div
            className={style.client__overlay_mobile}
            onClick={() => {
              setPlay(false)
              mobilePlayer.current.play()
            }}
          >
            <PlayIcon />
          </div>
        )}
      </div>
      <div className={style.client__button_wrap}>
        <Link to={`/?name=${title}`}>
          <motion.div
            className={style.client__button}
            variants={variants}
            initial="inital"
            animate="fade"
          >
            <span>Explore Website</span>
            <ArrowRightBlack />
          </motion.div>
        </Link>
      </div>
      <div className={style.client__contact}>
        <p>{"Gareth O’Connor, Managing Director"}</p>
        <p className={style.client__contact_details}>
          <a href="tel:021674364">021 674 364</a>
          <span>{"|"}</span>
          <a href="mailto: gareth.oconnor@harveycameron.co.nz">
            gareth.oconnor@harveycameron.co.nz
          </a>
        </p>
      </div>
    </div>
  )
}

const PlayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M188.3 147.1c7.5-4.3 16.8-5 24.2.4l144 88a24.1 24.1 0 0 1 0 41l-144 88a23.8 23.8 0 0 1-24.2.4A23.9 23.9 0 0 1 176 344V167.1c0-7.8 4.7-15.8 12.3-20zM512 256a256 256 0 1 1-512 0 256 256 0 0 1 512 0zM256 48a208 208 0 1 0 0 416 208 208 0 0 0 0-416z" />
  </svg>
)

export default Client
